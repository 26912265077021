<template>
  <div>
    <b-container class="mt-4">
      <b-form @submit="sendGroup">
        <b-card header-class="header">
          <template #header>
            <label
              v-text="'Yarışma Grubu Oluştur'"
              class="mr-2 text-white"
            ></label>

            <b-button
              :to="{
                name: 'CompetitionDetail',
                params: { id: $route.params.id },
              }"
              variant="primary"
              class="ml-4"
            >
              Yarışma Bilgileri
            </b-button>
          </template>
          <b-alert class="spacing" show>
            Bilgilerinizi girin ve <b>Gönder</b> butonuna tıklayın.
          </b-alert>

          <b-alert class="spacing" show variant="warning">
            Grubu oluşturduktan sonra <b>grup kodu</b> ile diğer sporcuların gruba katılmasını sağlayabilirsiniz.
          </b-alert>
         
          <b-row class="mb-4">
            <b-col>Kategori:</b-col>
            <b-col>
              <b-select
                :options="getCategorySelections"
                text-field="title"
                value-field="id"
                v-model="item.category"
                required
              ></b-select
            ></b-col>
          </b-row>
          <b-row class="mb-4" v-if="singleCompetition.branch
           == 60">
            <b-col>Cinsiyet:</b-col>
            <b-col>
              <b-select
                :options="getGenderOptions"
                text-field="text"
                value-field="value"
                v-model="item.gender"
                required
              ></b-select
            ></b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>Tekne Sınıfı:</b-col>
            <b-col>
              <b-select
                :options="getBoatClassSelections"
                text-field="title"
                value-field="id"
                v-model="item.boatType"
                required
              ></b-select
            ></b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col> Mesafe </b-col>
            <b-col>
              <b-select
                :options="getDistanceSelections"
                text-field="title"
                value-field="id"
                v-model="item.distance"
                required
              ></b-select>
            </b-col>
          </b-row>
          <b-row align-v="end">
            <b-col>
              <b-btn type="submit">Gönder</b-btn>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>
  
  
  <script>
import participantType from "../../../store/constants/participantType";
import { mapGetters } from "vuex";
import selectionConstants from "@/store/constants/selections";
import { genderOptions } from "@/store/constants/personel";
export default {
  data() {
    return {
      participantType,
      selectedDistance: "",
      selectedCategory: "",
      item: {
        category: "",
        boatType: "",
        distance: "",
        gender: "",
        competitionId: this.$route.params.id,
        clubId: null,
        personelId1: this.$store.getters.getUser.id,
        personelId2: null,
        personelId3: null,
        personelId4: null,
        formOfParticipation: "sportState",
      },
      fields: [
        { key: "category", label: "Kategori" },
        { key: "distance", label: "Mesafe" },
        { key: "personel", label: "Personel" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("initManagement");
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
    this.$store.commit("setClubCompetitionPersonel", []);
  },
  computed: {
    ...mapGetters({
      getSelections: "getManagementItems",
      clubMembers: "getClubCompetitionPersonel",
      singleCompetition: "getSingleCompetition",
    }),
    getGenderOptions: {
      get() {
        return genderOptions.concat([{ text: "Mix", value: "mixed" }]);
      },
    },

    getBoatClassSelections: {
      get() {
        return [{ title: "Seçin", id: "", option: 0 }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.BOAT_CLASS; //BOAT FILTER
          }).filter((e) => {
            if(this.singleCompetition.branch == 60){
              return e.title == "4+1"
            }else{
              return e.title != "4+1"
            }
          })
        );
      },
    },
    getCategorySelections: {
      get() {
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections.filter((e) => {
            return e.category == selectionConstants.CATEGORY; //CATEGORY FILTER
          })
        );
      },
    },
    getDistanceSelections: {
      get() {
        const specials = [];
        return [{ title: "Seçin", id: "" }].concat(
          this.getSelections
            .map((e) => {
              if (
                e.category == selectionConstants.DISTANCE &&
                e.option == this.singleCompetition.branch
              ) {
                specials.push(`${e.option}`);
              }
              return e;
            })
            .filter((e) => {
              return (
                e.category == selectionConstants.DISTANCE &&
                e.option ==
                  (specials.includes(this.singleCompetition.branch)
                    ? this.singleCompetition.branch
                    : undefined)
              ); //DISTANCE FILTER
            })
        );
      },
    },
  },
  methods: {
    async sendGroup(event) {
      event.preventDefault();

      var res = await this.$store.dispatch("createIndividualGroup", this.item);
      console.log(res);

   
      if (res.success == true) {
        this.$store.dispatch("notification/setNotifications", [
          {
            title: "Başarılı!",
            body: "Kaydınız başarıyla alındı. Lütfen federayon onayını bekleyiniz.",
            type: "success",
          },
        ]).then(() => {
           this.$router.push({
             name: "IndividualCompetitionGroupsList",
             params: { id: this.$route.params.id },
           });
        });
      }
    },
  },
};
</script>
  
  
  <style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>