module.exports = {
    bloodOptions: [
        { value: "", text: "Seçin" },
        {
            text: "0 Rh(+)",
            value: "0plus",
        },
        {
            text: "0 Rh(-)",
            value: "0minus",
        },
        {
            text: "A Rh(+)",
            value: "aplus",
        },
        {
            text: "A Rh(-)",
            value: "aminus",
        },
        {
            text: "B Rh(+)",
            value: "bplus",
        },
        {
            text: "B Rh(-)",
            value: "bminus",
        },
        {
            text: "AB Rh(+)",
            value: "abplus",
        },
        {
            text: "AB Rh(-)",
            value: "abminus",
        },
    ],
    reasonOptions: [
        { value: "", text: "Seçin" },
        {
            text: "Değiştirme",
            value: "change",
        },
        {
            text: "Kayıp",
            value: "lost",
        },
        {
            text: "Yenileme",
            value: "renewal",
        },
    ],
    religionOptions: [
        { value: "", text: "Seçin" },
        {
            text: "İslam",
            value: "muslim",
        },
        {
            text: "Diğer",
            value: "other",
        },
    ],
    maritalOptions: [
        { value: "", text: "Seçin" },
        {
            text: "Bekar",
            value: "single",
        },
        {
            text: "Evli",
            value: "married",
        },
    ],
    nationalityOptions: [
        { value: "", text: "Seçin" },
        {
            text: "TC",
            value: "turkish",
        },
        {
            text: "Yabancı",
            value: "foreigner",
        },
    ],
    genderOptions: [
        { value: "", text: "Seçin", disabled:true },
        {
            text: "Erkek",
            value: "male",
        },
        {
            text: "Kadın",
            value: "female",
        },
    ],
    branchOptions: [
        { value: "", text: "Seçin" },
        {
            text: "Akarsu",
            value: "running",
        },
        {
            text: "Durgunsu",
            value: "still",
        },
    ],
    boatOptions: [
        { value: "", text: "Seçin" },
        {
            text: "C1",
            value: "c1",
        },
        {
            text: "C2",
            value: "c2",
        },
        {
            text: "C4",
            value: "c4",
        },
        {
            text: "K1",
            value: "k1",
        },
        {
            text: "K2",
            value: "k2",
        },
        {
            text: "K4",
            value: "k4",
        },
    ],
    activityOptions: [
        { value: "", text: "Seçin" },
        {
            text: "Faal Olmayan",
            value: "stopped",
        },
        {
            text: "Faal",
            value: "active",
        },
    ],
    boardOptions: [
        { value: "", text: "Seçin" },
        {
            value: "0",
            text: "Akarsu Merkez Hakem Kurulu",
        },
        { value: "1", text: "Akarsu Teknik Kurul" },
        { value: "2", text: "Basın Kurulu" },
        { value: "3", text: "Bilgi İşlem Kurulu" },
        { value: "4", text: "Bilim Kurulu" },
        { value: "5", text: "Danışma Kurulu" },
        { value: "6", text: "Dış İlişkiler Kurulu" },
        {
            value: "7",
            text: "Durgunsu Merkez Hakem Kurulu",
        },
        { value: "8", text: "Durgunsu Teknik Kurul" },
        { value: "9", text: "Hukuk Kurulu" },
        { value: "10", text: "Lig Kurulu" },
        { value: "11", text: "Onur Kurulu" },
        { value: "12", text: "Organizasyon Kurulu" },
        { value: "13", text: "Sağlık Kurulu" },
        {
            value: "14",
            text: "Sosyal Faaliyetler Kurulu",
        },
        { value: "15", text: "Yönetim Kurulu" },
        { value: "16", text: "Yürütme Kurulu" },
    ],
    educationOptions: [
        { value: "", text: "Seçin" },
        {
            text: "İlköğretim",
            value: "primary",
        },
        {
            text: "Lise",
            value: "high",
        },
        {
            text: "Üniversite",
            value: "college",
        },
    ],
} 